import axios from "axios";
import { onRequestFailed, onRequestSuccess } from "@/shared/api/requestInterceptors";
import { onResponseFailed, onResponseSuccess } from "@/shared/api/responseInterceptors";

const config = {
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { "Cache-Control": "no-cache" },

};

const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(onRequestSuccess, onRequestFailed);
axiosInstance.interceptors.response.use(onResponseSuccess, onResponseFailed);

const { get, post, put } = axiosInstance;
const destroy = axiosInstance.delete;

export {
  get,
  post,
  put,
  destroy,
  axiosInstance,
};
