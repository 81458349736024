import { faBell,
  faBuilding,
  faCalendarAlt,
  faCheck, faCoinBlank, faCoins,
  faCopy,
  faDownload,
  faEuroSign, faEye,
  faFileContract,
  faFilter, faGauge,
  faPaperPlane,
  faPiggyBank, faSack,
  faSearch,
  faTimes,
  faUser,
  faCheckDouble,
  faSignature,
  faUsers,
  faUserTie } from "@fortawesome/pro-solid-svg-icons";
import { faBadgeCheck, faCheckCircle, faCircleHalfStroke, faClock } from "@fortawesome/pro-regular-svg-icons";

const icons = [
  faUsers,
  faUser,
  faSearch,
  faFileContract,
  faBuilding,
  faPaperPlane,
  faEuroSign,
  faCheck,
  faTimes,
  faCalendarAlt,
  faDownload,
  faUserTie,
  faPiggyBank,
  faCheckCircle,
  faClock,
  faBell,
  faBadgeCheck,
  faCircleHalfStroke,
  faCopy,
  faFilter,
  faGauge,
  faCoinBlank,
  faCoins,
  faSack,
  faEye,
  faCheckDouble,
  faSignature,
];

const aliases = {
  notification: "fas fa-bell",
  theme: "far fa-circle-half-stroke",
  filter: "fas fa-filter",

  funder: "fas fa-piggy-bank",
  consumer: "fas fa-user",
  business: "fas fa-building",
  user: "fas fa-user",
  search: "fas fa-search",
  dashboard: "fas fa-gauge",

  fundingContract: "fas fa-file-contract",

  fundingContractStatusDraft: "fas fa-pencil",
  fundingContractStatusSent: "fas fa-paper-plane",
  fundingContractStatusAccepted: "fas fa-signature",
  fundingContractStatusValidated: "fas fa-check-double",
  fundingContractStatusActive: "fas fa-euro-sign",
  fundingContractStatusInactive: "fas fa-times",

  true: "fas fa-check",
  false: "fas fa-times",

  calender: "fas fa-calendar-alt",
  download: "fas fa-download",
  copy: "fas fa-copy",
  open: "fas fa-eye",

  waiting: "far fa-clock",
  done: "far fa-check-circle",

  tasksDone: "far fa-badge-check",

  bronzePartner: "fas fa-coin-blank",
  silverPartner: "fas fa-coins",
  goldPartner: "fas fa-sack",
};

export default {
  aliases,
  icons,
};
