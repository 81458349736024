import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";
import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { useImpersonateStore } from "@/modules/impersonate/impersonateStore";
export function onRequestSuccess (config: InternalAxiosRequestConfig) {
  const authorisationStore = useAuthorisationStore();
  const accessToken = authorisationStore.accessToken;
  const { impersonateId } = useImpersonateStore();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  if (impersonateId) {
    config.headers["X-Impersonate"] = impersonateId;
  }
  return config;
}

export function onRequestFailed (error: AxiosError) {
  return Promise.reject(error);
}
