import { useAlert } from "@debitroom-software/vuetify-alert";
import i18n from "@/shared/plugins/i18n";
import { AxiosRequestConfig, isAxiosError } from "axios";
import { userManager } from "@/modules/authorisation/userManager";
import { axiosInstance } from "@/shared/api/apiInstance";
import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";

const { showAlert, closeAlert } = useAlert();
const { t } = i18n.global as any;


export function onResponseSuccess (response: any) {
  const authorisationStore = useAuthorisationStore();
  authorisationStore.redirectToAfterLoginUrl = undefined;
  return response;
}

export function onResponseFailed (error: any) {
  if (isAxiosError(error) && error.code === "ERR_NETWORK") {
    handleNetworkError();
    return Promise.reject({
      isHandled: true,
      message: "Network error handled gracefully",
    });
  }
  if (isAxiosError(error) && error.response && error.response.status === 429) {
    handle429Error(error);
    return Promise.reject({
      isHandled: true,
      message: "429 error handled gracefully",
    });
  }
  if (isAxiosError(error) && error.response && error.response.status === 401) {
    return handle401Error(error);
  }

  return Promise.reject(error);
}

let timeout: ReturnType<typeof setTimeout> | null;
let interval: ReturnType<typeof setInterval> | null;
function handle429Error (error: any) {
  let seconds: number = 0;
  seconds = error.response.headers["retry-after"];
  set429Alert(seconds);

  if (timeout) clearTimeout(timeout);
  if (interval) clearTimeout(interval);

  timeout = setTimeout(() => {
    closeAlert();
    if (interval) clearInterval(interval);
  }, seconds * 1000);


  interval = setInterval(() => {
    seconds--;
    set429Alert(seconds);
  },
  1000);
}

async function handle401Error (error: Error) {
  if (!isAxiosError(error) || !error.response || error.response.status !== 401) throw "wrong error handling";
  try {
    await userManager.signinSilent();
    return axiosInstance.request(error?.config as AxiosRequestConfig<any>);
  } catch (error) {
    await userManager.signinRedirect();
  }
}

function handleNetworkError () {
  showAlert({
    title: t("global.errors.titles.network"),
    message: t("global.errors.messages.network"),
    type: "error",
    hasCloseButton: true,
  });
}

function set429Alert (seconds: number) {
  showAlert({
    title: t("global.errors.titles.429"),
    message: t("global.errors.messages.429", { seconds }),
    hasCloseButton: false,
  });
}
