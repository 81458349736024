import { defineStore } from "pinia";
import { ref } from "vue";

export const useImpersonateStore = defineStore("impersonate", () => {
  const impersonateId = ref<string>();

  function setImpersonateId (id: string) {
    impersonateId.value = id;
  }

  return {
    impersonateId,
    setImpersonateId,
  };
});
