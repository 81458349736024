<script setup lang="ts">
import { TheSnackbarQueue } from "@debitroom-software/vuetify-snackbar-queue";
import { TheAlertDisplayer, TheDialogDisplayer } from "@debitroom-software/vuetify-alert";
import { useAuthorisationStore } from "@/modules/authorisation/authorisationStore";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const authorisationStore = useAuthorisationStore();

router.isReady().then(() => {
  if (!authorisationStore.redirectToAfterLoginUrl) {
    authorisationStore.redirectToAfterLoginUrl = route.fullPath;
  }
});
</script>

<template>
  <router-view />
  <TheSnackbarQueue />
  <TheAlertDisplayer />
  <TheDialogDisplayer />
</template>
